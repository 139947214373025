import { sendThePassword } from "@Services/sendThePassword";

export const SendPassword = async (token, password, navigate) => {
  const response = await sendThePassword(token, password);

  if (response.isSuccess === true) {
    navigate("/recuperar/completado");
  }

  return response;
};
