import { postLogin_service } from "@Services/login";

interface RelationEmployee {
  CompanyId: number;
  CompanyName: string;
  Locals: {
    LocalName: string;
    LocalId: number;
  }[];
}

export const login_adapter = async (params) => {
  const response = await postLogin_service(params);
  if (!response.isSuccess) {
    return response;
  }

  const data = response.data;

  const relationEmployee: RelationEmployee[] | null = JSON.parse(
    data.RelationEmployee
  );
  const adaptData = {
    accessToken: data.access_token,
    tokenType: data.token_type,
    expiresIn: data.expires_in,
    relationEmployee: relationEmployee || null,
  };

  return {
    ...response,
    data: adaptData,
  };
};
