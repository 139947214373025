// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gO8CGpNoKYbHiqrN65Aw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
}

.Id_luSsdIYQGcInH3AA_ {
  font-size: var(--font-size-title);
  color: #45348e;
}

.agjb3Et478scKwp_lTRQ {
  font-size: 1rem;
  color: #45348e;
  width: 100%;
  max-width: 700px;
  text-align: center;
  background-color: #f9f6ff;
  border-top: 2px solid #45348e;
  border-bottom: 2px solid #45348e;
  padding: 20px 16px;
  margin: 10px auto 20px auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/forgotPassword/components/SentIndicator/SentIndicator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,iCAAiC;EACjC,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,6BAA6B;EAC7B,gCAAgC;EAChC,kBAAkB;EAClB,2BAA2B;AAC7B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  gap: 20px;\n}\n\n.title {\n  font-size: var(--font-size-title);\n  color: #45348e;\n}\n\n.paragraph {\n  font-size: 1rem;\n  color: #45348e;\n  width: 100%;\n  max-width: 700px;\n  text-align: center;\n  background-color: #f9f6ff;\n  border-top: 2px solid #45348e;\n  border-bottom: 2px solid #45348e;\n  padding: 20px 16px;\n  margin: 10px auto 20px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `gO8CGpNoKYbHiqrN65Aw`,
	"title": `Id_luSsdIYQGcInH3AA_`,
	"paragraph": `agjb3Et478scKwp_lTRQ`
};
export default ___CSS_LOADER_EXPORT___;
