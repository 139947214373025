import { ILoginForm } from "@Models/interfaces/interfaces";
import { loginSchema } from "@Pages/login/schemas/loginForm.schema";
import { Dispatch, SetStateAction, useEffect } from "react";

const ValidateLoginForm = (
  loginForm: ILoginForm,
  setCanUserContinue: Dispatch<SetStateAction<boolean>>
) => {
  useEffect(() => {
    (async () => {
      try {
        await loginSchema.validate({
          email: loginForm.email,
          password: loginForm.password,
        });
        setCanUserContinue(true);
      } catch (error) {
        setCanUserContinue(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginForm]);
};

export default ValidateLoginForm;
