import { Route, Routes } from "react-router-dom";
import { Login } from "@Pages/login/Login.page"; // Eliminado
import { ForgotPasswordView } from "@Pages/forgotPassword/view/ForgotPassword.view";
import { ROUTES } from "@Models/routes";
// @ts-ignore
import { NotFoundPage, UnauthorizedPage } from "@viuti/recursos";
import { ConfirmNewPassword } from "@Pages/forgotPassword/view/ConfirmNewPassword";
import { CompletedPasswordChange } from "@Pages/forgotPassword/view/CompletedPasswordChange";
import { CustomLogin } from "@Pages/CustomLogin/CustomLogin";

const LoginRoute = () => {
  return (
    <Routes>
      {/* Ruta de inicio de sesión personalizada */}
      <Route path={ROUTES.login} element={<CustomLogin />}></Route>
      <Route path={ROUTES.recuperar} element={<ForgotPasswordView />}></Route>
      <Route path={ROUTES.confirmar} element={<ConfirmNewPassword />}></Route>
      <Route
        path={ROUTES.completado}
        element={<CompletedPasswordChange />}
      ></Route>
      <Route path="/acceso-no-autorizado" element={<UnauthorizedPage />} />
      <Route path="*" element={<NotFoundPage />}></Route>
    </Routes>
  );
};

export default LoginRoute;
