import axios from "axios";
export const GW_URL = "https://gw-qa.prikly.io";
const baseUrlApiGw = `${GW_URL}/onboarding`;

export const sendTheEmail = async (email) => {
  try {
    const results = await axios.post(
      `${baseUrlApiGw}/v1/Onboarding/SendEmailResetPassword?email=${email}`
    );
    return {
      isSuccess: true,
      data: results.data,
      message: "Se ha enviado el correo correctamente",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: null,
      message: error.response.data.message,
    };
  }
};
