// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m8GiOhVo8xfsGyc07JUf {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mvFFzRB4yCmDZeG3P2v5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  & .tcYcKyXRMajLyJPU9LSe {
    width: 7rem;
    height: 7rem;
    -webkit-animation: ZV3wdLPfmQgHTnmf7vxI 1.5s ease-in-out both;
    animation: ZV3wdLPfmQgHTnmf7vxI 1.5s ease-in-out both;
  }
}

.BcYd7MCEYEw_sneG8Mgl {
  color: #4d4d4d;
  text-align: center;
  font-family: var(--CARPENTER-VIUTI-CORE-FONT-PRIMARY);
  font-size: var(--CARPENTER-VIUTI-CORE-FONT-SIZE-TITLE);
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.s5OtKC_3YMZv_gKT8ebU {
  width: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 0 20px;
}

.hzK4ByVUUmaIhMvEoSOp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.bjv6Q9Jfjf_7fHCHTcx5 {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-between;
  gap: 10px;
}

@media screen and (max-width: 500px) {
  .bjv6Q9Jfjf_7fHCHTcx5 {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/forgotPassword/style/ForgotPasswordView.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;;EAET;IACE,WAAW;IACX,YAAY;IACZ,6DAAkD;IAClD,qDAA0C;EAC5C;AACF;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qDAAqD;EACrD,sDAAsD;EACtD,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".container {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.header {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n\n  & .logo {\n    width: 7rem;\n    height: 7rem;\n    -webkit-animation: heartbeat 1.5s ease-in-out both;\n    animation: heartbeat 1.5s ease-in-out both;\n  }\n}\n\n.subTitle {\n  color: #4d4d4d;\n  text-align: center;\n  font-family: var(--CARPENTER-VIUTI-CORE-FONT-PRIMARY);\n  font-size: var(--CARPENTER-VIUTI-CORE-FONT-SIZE-TITLE);\n  font-style: normal;\n  font-weight: 600;\n  line-height: 26px;\n}\n\n.containerContent {\n  width: 28rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 3rem;\n  padding: 0 20px;\n}\n\n.containerInput {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 3rem;\n}\n\n.buttonsContainer {\n  width: 100%;\n  display: flex;\n  text-align: center;\n  justify-content: space-between;\n  gap: 10px;\n}\n\n@media screen and (max-width: 500px) {\n  .buttonsContainer {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `m8GiOhVo8xfsGyc07JUf`,
	"header": `mvFFzRB4yCmDZeG3P2v5`,
	"heartbeat": `ZV3wdLPfmQgHTnmf7vxI`,
	"logo": `tcYcKyXRMajLyJPU9LSe`,
	"subTitle": `BcYd7MCEYEw_sneG8Mgl`,
	"containerContent": `s5OtKC_3YMZv_gKT8ebU`,
	"containerInput": `hzK4ByVUUmaIhMvEoSOp`,
	"buttonsContainer": `bjv6Q9Jfjf_7fHCHTcx5`
};
export default ___CSS_LOADER_EXPORT___;
