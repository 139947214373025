// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pWrppS_mMyWSHs9Sc62L {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.L_lWwMTvvWuanUrmslkU {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  & .P27boWZEbEPoZ8DQPyQu {
    width: 7rem;
    height: 7rem;
    -webkit-animation: C9rHOLpHQcs4p4CGBnAC 1.5s ease-in-out both;
    animation: C9rHOLpHQcs4p4CGBnAC 1.5s ease-in-out both;
  }
}

.m5gbYpwPU4GcQ7ZK6d0f {
  color: #4d4d4d;
  text-align: center;
  font-family: var(--CARPENTER-VIUTI-CORE-FONT-PRIMARY);
  font-size: var(--CARPENTER-VIUTI-CORE-FONT-SIZE-TITLE);
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.g07bGH_rhEMEogbcNXPj {
  width: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 0 20px;
}

._2QEe0UMf9Rn9iLn8IAQ {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/forgotPassword/style/ConfirmNewPassword.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;;EAET;IACE,WAAW;IACX,YAAY;IACZ,6DAAkD;IAClD,qDAA0C;EAC5C;AACF;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qDAAqD;EACrD,sDAAsD;EACtD,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX","sourcesContent":[".container {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.header {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n\n  & .logo {\n    width: 7rem;\n    height: 7rem;\n    -webkit-animation: heartbeat 1.5s ease-in-out both;\n    animation: heartbeat 1.5s ease-in-out both;\n  }\n}\n\n.subTitle {\n  color: #4d4d4d;\n  text-align: center;\n  font-family: var(--CARPENTER-VIUTI-CORE-FONT-PRIMARY);\n  font-size: var(--CARPENTER-VIUTI-CORE-FONT-SIZE-TITLE);\n  font-style: normal;\n  font-weight: 600;\n  line-height: 26px;\n}\n\n.containerContent {\n  width: 28rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 3rem;\n  padding: 0 20px;\n}\n\n.containerInput {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pWrppS_mMyWSHs9Sc62L`,
	"header": `L_lWwMTvvWuanUrmslkU`,
	"heartbeat": `C9rHOLpHQcs4p4CGBnAC`,
	"logo": `P27boWZEbEPoZ8DQPyQu`,
	"subTitle": `m5gbYpwPU4GcQ7ZK6d0f`,
	"containerContent": `g07bGH_rhEMEogbcNXPj`,
	"containerInput": `_2QEe0UMf9Rn9iLn8IAQ`
};
export default ___CSS_LOADER_EXPORT___;
