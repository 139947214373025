import React from "react";
// @ts-ignore
import style from "../style/CompletePasswordChange.module.css";
import { OperationSuccessIcon } from "@Models/Icons";
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import { useNavigate } from "react-router-dom";

export const CompletedPasswordChange = () => {
  const navigate = useNavigate();

  return (
    <div className={style.viewContainer}>
      <div className={style.viewContent}>
        <form className={`${style.formContainer} ${style.formContainer}`}>
          <div className={style.headerContainer}>
            <h2>¡Completado!</h2>
            <p>Has cambiado exitosamente la contarseña</p>
          </div>
          <img src={OperationSuccessIcon} alt="completado" />
          <ButtonWithoutIcon
            width="100%"
            isPrimary={true}
            textBttn="Volver al inicio"
            handleClick={() => {
              navigate("/");
            }}
          />
        </form>
      </div>
    </div>
  );
};
