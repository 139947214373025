import { SendEmail } from "@Adapters/sendTheEmail/sendTheEmailAdapter";

export const handleSubmit = async (
  setOnCloseConfirmation,
  setIsLoading,
  email
) => {
  setIsLoading(true);
  await SendEmail(email);
  setIsLoading(false);
  setOnCloseConfirmation(false);
};
