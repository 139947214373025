import React, { useState } from "react";
// @ts-ignore
import style from "../style/ForgotPasswordView.module.css";
import { LogoAppVertical } from "@Models/Icons";
import {
  ButtonWithoutIcon,
  TextInput,
  //@ts-ignore
} from "@viuti/recursos";

import SentIndicator from "../components/SentIndicator/SentIndicator";
import { handleSubmit } from "../Functions/handleSubmit";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordView = () => {
  const navigate = useNavigate();
  const REGUEX_EMAIL = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [loginForm, setLoginForm] = useState({
    email: null,
  });
  const [emailError, setEmailError] = useState(null);
  const [onCloseConfirmation, setOnCloseConfirmation] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginForm({ ...loginForm, [name]: value });
    setEmailError(!REGUEX_EMAIL.test(value));
  };

  return (
    <div className={style.container}>
      {!onCloseConfirmation && (
        <SentIndicator setOnClose={setOnCloseConfirmation} />
      )}
      <div className={style.containerContent}>
        <span className={style.header}>
          <img className={style.logo} src={LogoAppVertical} alt="Prikly" />
        </span>
        <h1 className={style.subTitle}>
          Escribe el correo electrónico asociado a la cuenta que deseas
          recuperar, te enviaremos un correo electrónico para verificar tu
          identidad. Sigue los pasos indicados en el correo.
        </h1>
        <div className={style.containerInput}>
          <TextInput
            width="100%"
            label="Correo electrónico"
            placeholder="Ingresa tu correo electrónico"
            value={loginForm.email === null ? "" : loginForm.email}
            handleChange={(e) => handleEmailChange(e)}
            name="email"
            error={"Debes ingresar un correo electronico valido"}
            touched={emailError}
            useCircleAlert={false}
            id="login_forgotPassword_input_email"
          />
          <div className={style.buttonsContainer}>
            <ButtonWithoutIcon
              width="100%"
              isPrimary={false}
              textBttn="Volver"
              handleClick={() => {
                navigate("/");
              }}
              id="login_forgotPassword_click_goBack"
            />
            <ButtonWithoutIcon
              width="100%"
              isPrimary={true}
              isDisabled={
                loginForm.email === null ||
                loginForm.email === "" ||
                emailError ||
                isLoading
              }
              textBttn="Continuar"
              isLoading={isLoading}
              handleClick={() => {
                handleSubmit(
                  setOnCloseConfirmation,
                  setIsLoading,
                  loginForm.email
                );
              }}
              id="login_forgotPassword_click_submitForm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
