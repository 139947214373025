import { BrowserRouter as Router } from "react-router-dom";
import { HOME_ROUTE } from "@Models/routes";
import LoginRoute from "@Routes/login.route";

// Route page of the application
export default function App() {
  return (
    <Router basename={HOME_ROUTE}>
      <LoginRoute />
    </Router>
  );
}
