import React, { useEffect, useState } from "react";
// @ts-ignore
import styles from "./CustomLogin.module.css";
import { login_adapter } from "@Adapters/login/login.adapter";
import {
  SlideNotification,
  PasswordInput,
  TextInput,
  ButtonWithoutIcon,
  LinkButton,
  SelectInput,
  LoadingSpinner,
  // @ts-ignore
} from "@viuti/recursos";
import { selectLocal_adapter } from "@Adapters/login/selectLocal.adapter";
import { useNavigate } from "react-router-dom"; // Cambiar useHistory a useNavigate
import { LogoAppVertical } from "@Models/Icons";
import ValidateLoginForm from "@Pages/login/view/LoginForm/hook/validateLoginForm";

export const CustomLogin = () => {
  const navigate = useNavigate(); // Usar useNavigate en lugar de useHistory
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [selectedLocal, setSelectedLocal] = useState(null);
  const [view, setView] = useState("login"); // Estado para manejar la vista actual
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [canUserContinue, setCanUserContinue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("client_id", "passwordclient");
  params.append("client_secret", "passwordclient-secret");
  params.append("username", email);
  params.append("password", password);

  ValidateLoginForm(
    {
      email,
      password,
    },
    setCanUserContinue
  );
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsButtonLoading(true);

    const response = await login_adapter(params);

    if (!response.isSuccess) {
      setIsButtonLoading(false);
      setStateResponse({
        message: response.message,
        status: 400,
      });
      return;
    }

    setStateResponse({
      message: "Inicio de sesión exitoso",
      status: 200,
    });
    setCompanies(response.data.relationEmployee);
    setView("selectCompany");
  };

  const handleCompanySelect = (e) => {
    const id = e.target.value;
    const company = companies.find(
      (company) => String(company.CompanyId) === String(id)
    );
    setSelectedCompany(company);
    setSelectedLocal(null);
  };

  const handleLocalSelect = async (e, company = selectedCompany) => {
    const local = company?.Locals.find(
      (local) => String(local.LocalId) === String(e.target.value)
    );
    if (local) {
      params.append("idEmpresa", selectedCompany?.CompanyId || "");
      params.append("idLocal", local.LocalId);
      setIsLoading(true);
      const response = await selectLocal_adapter(params);
      setIsLoading(false);
      if (!response.isSuccess) {
        setStateResponse({
          message: response.message,
          status: 400,
        });
        return;
      }
      localStorage.setItem(
        "selectedLocal",
        JSON.stringify({
          id: local.LocalId,
          nombre: local.LocalName,
        })
      );
      // Completar la información de la cuenta
      const account_info = {
        employee: {
          id: response.data.IdEmpleado,
          name: response.data.Name,
          lastName: response.data.LastName,
          email: response.data.Email,
          profileImage: response.data.ProfileImage || "",
          alias: response.data.Alias || "",
        },
        lounge: {
          id: local.LocalId,
          name: local.LocalName,
        },
        company: {
          id: response.data.CompanyId,
          name: response.data.CompanyName,
        },
        role: {
          id: response.data.IdRole,
          name:
            response.data.Role?.charAt(0)?.toUpperCase() +
            response.data.Role?.slice(1)?.toLowerCase(),
        },
        plan: {
          id: response.data.PlanId,
          name: response.data.PlanName,
        },
      };

      const token = response.data.access_token;
      localStorage.setItem("account_info", JSON.stringify(account_info));
      localStorage.setItem("access_token", token);

      // Decodificar el token y capturar la propiedad StartView
      const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decodifica el payload del JWT
      // Navegar a la propiedad StartView

      navigate(decodedToken.StartView); // Cambia a la vista especificada en StartView
    }
  };

  const handleBackToCompany = () => {
    setSelectedLocal(null);
    setSelectedCompany(null);
    setView("selectCompany");
  };

  useEffect(() => {
    // Redirigir según la cantidad de empresas y locales
    if (companies.length === 1) {
      setSelectedCompany(companies[0]);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    // Verificar si hay un solo local en la empresa seleccionada
    if (selectedCompany?.Locals.length === 1) {
      const local = selectedCompany.Locals[0];
      setSelectedLocal(local);
      handleLocalSelect({ target: { value: local.LocalId } }, selectedCompany);
      return;
    } else if (selectedCompany?.Locals.length > 1) {
      setView("selectLocal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    // Verificar si existe un access_token en localStorage
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decodifica el payload del JWT
      if (decodedToken.StartView) {
        navigate(decodedToken.StartView); // Navegar a la propiedad StartView
      }
    }
  }, [navigate]); // Asegúrate de incluir navigate en las dependencias

  if (isLoading)
    return (
      <div className={styles.section}>
        <LoadingSpinner isVisible={true} size={64} />
      </div>
    );

  return (
    <div className={styles.section}>
      {view === "login" && (
        <article className={styles.mainForm}>
          <span className={styles.header}>
            <img className={styles.logo} src={LogoAppVertical} alt="Prikly" />
            <h1>
              La plataforma inteligente que digitaliza la gestión de negocios.
            </h1>
          </span>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputContainer}>
              <TextInput
                label="Correo electrónico"
                placeholder="Ingresa tu correo electrónico"
                value={email} // Cambiado a 'email' en lugar de 'loginForm.email'
                handleChange={(e) => setEmail(e.target.value)} // Actualiza el estado directamente
                touched={email !== ""} // Verifica si el campo ha sido tocado
                useCircleAlert={false}
                id="login_signIn_input_email"
                inputProps={{
                  autoComplete: "username", // Corregido 'autocomplete' a 'autoComplete'
                }}
              />
              {stateResponse.message && ( // Muestra el mensaje de error si existe
                <span
                  className={`${styles.errorMessageContainer} ${
                    stateResponse.status === 400
                      ? styles.errorMessageContainer__visible
                      : ""
                  }`}
                >
                  <p className={styles.errorMessage}>{stateResponse.message}</p>
                </span>
              )}
            </div>
            <div className={styles.inputContainer}>
              <PasswordInput
                label="Contraseña"
                inputProps={{
                  placeholder: "Ingresa tu contraseña",
                  value: password, // Cambiado a 'password' en lugar de 'loginForm.password'
                  onChange: (e) => setPassword(e.target.value), // Actualiza el estado directamente
                  type: isPasswordVisible ? "text" : "password",
                  autoComplete: "current-password",
                }}
                visibility={{
                  isVisible: isPasswordVisible,
                  setIsVisible: setIsPasswordVisible,
                  isToggleAvailable: true,
                }}
                touched={password !== ""} // Verifica si el campo ha sido tocado
                id="login_signIn_input_password"
              />
              {stateResponse.message && ( // Muestra el mensaje de error si existe
                <span
                  className={`${styles.errorMessageContainer} ${
                    stateResponse.status === 400
                      ? styles.errorMessageContainer__visible
                      : ""
                  }`}
                >
                  <p className={styles.errorMessage}>{stateResponse.message}</p>
                </span>
              )}
            </div>
            <footer className={styles.footer}>
              <ButtonWithoutIcon
                width="100%"
                isPrimary={true}
                textBttn="Iniciar sesión"
                isDisabled={!canUserContinue || isButtonLoading}
                handleClick={() => {}}
                isLoading={isButtonLoading}
                buttonProps={{
                  type: "submit",
                }}
                id="login_signIn_click_submitForm"
              />
              <div className={styles.utilLinks}>
                <LinkButton
                  path={"/recuperar"}
                  id="login_signIn_click_forgotPassword"
                >
                  ¿Olvidaste tu contraseña?
                </LinkButton>
                <LinkButton
                  path={"/onboarding/admin"}
                  id="login_signIn_click_onboardingAdmin"
                >
                  ¿No tienes cuenta?
                </LinkButton>
              </div>
            </footer>
          </form>
        </article>
      )}
      {view === "selectCompany" && (
        <div>
          <span className={styles.header}>
            <img className={styles.logo} src={LogoAppVertical} alt="Prikly" />
            <h1>Selecciona una empresa</h1>
          </span>
          <SelectInput
            label=""
            placeholder="Seleccionar una empresa"
            value={selectedCompany?.CompanyId || ""}
            items={companies.map((company) => ({
              value: company.CompanyId,
              name: company.CompanyName,
            }))}
            handleChange={handleCompanySelect} // Asegúrate de que esta función maneje el cambio
            name="companySelect"
            required
            touched={!!selectedCompany} // Verifica si se ha seleccionado una empresa
          />
        </div>
      )}
      {view === "selectLocal" && (
        <div>
          <span className={styles.header}>
            <img className={styles.logo} src={LogoAppVertical} alt="Prikly" />
            <h1>Selecciona un local</h1>
          </span>
          <SelectInput
            label=""
            placeholder="Seleccionar un local"
            value={selectedLocal?.LocalId || ""}
            items={
              selectedCompany?.Locals.map((local) => ({
                value: local.LocalId,
                name: local.LocalName,
              })) || []
            }
            handleChange={handleLocalSelect} // Asegúrate de que esta función maneje el cambio
            name="localSelect"
            required
            touched={!!selectedLocal} // Verifica si se ha seleccionado un local
          />
          {companies.length < 1 && (
            <div className={styles.backButton}>
              <ButtonWithoutIcon
                width="100%"
                isPrimary={false} // Cambiado a false para que no sea un botón primario
                textBttn="Volver atrás"
                isDisabled={false} // Habilitado siempre
                handleClick={handleBackToCompany} // Llama a handleBackToCompany al hacer clic
                buttonProps={{
                  type: "button", // Cambiado a "button" para evitar el envío del formulario
                }}
                id="login_backToCompany_click"
              />
            </div>
          )}
        </div>
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

// Definición de la interfaz para la empresa seleccionada
interface Company {
  CompanyId: string;
  CompanyName: string;
  Locals: Local[]; // Los locales asociados a la empresa
}

interface Local {
  LocalId: string;
  LocalName: string;
}
