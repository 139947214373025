// @ts-ignore
import { ModalAcceptCancel, Icon } from "@viuti/recursos";
import { ValidatedIcon } from "@Models/Icons";
// @ts-ignore
import style from "./SentIndicator.module.css";
import { useNavigate } from "react-router-dom";

const SentIndicator = ({ setOnClose }: any) => {
  const navigate = useNavigate();

  const modalProps = {
    showButtons: {
      showButtonTwo: true,
    },
    children: <SentIndicatorChildren />,
    buttonTwo: {
      textButtonTwo: "Continuar",
      actionButtonTwo: () => {
        navigate("/");
        setOnClose(true);
      },
    },
    noSizeLimit: true,
  };

  return <ModalAcceptCancel {...modalProps} />;
};

const SentIndicatorChildren = () => {
  return (
    <div className={style.container}>
      <Icon size={96} path={ValidatedIcon} />
      <h1 className={style.title}>Listo!</h1>
      <p className={style.paragraph}>
        Hemos enviado un correo electrónico a la dirección que indicaste. Si
        este correo está registrado en Prikly, recibirás un mensaje en tu
        bandeja de entrada.
      </p>
    </div>
  );
};

export default SentIndicator;
