import React from "react";
// @ts-ignore
import style from "../style/ConfirmNewPassword.module.css";
import { LogoAppVertical } from "@Models/Icons";
import {
  ButtonWithoutIcon,
  PasswordInput,
  //@ts-ignore
} from "@viuti/recursos";
import { handleSubmitPassword } from "../Functions/handleSubmitPassword";
import { useNavigate } from "react-router-dom";

export const ConfirmNewPassword = () => {
  const navigate = useNavigate();
  const PASSWORD_REGUEX = /^(?=.*[a-zA-Z])(?=.*\d).*$/g;
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loginForm, setLoginForm] = React.useState({
    password: null,
    repeatPassword: null,
  });
  const [passwordError, setPasswordError] = React.useState({
    passwordError: null,
    repeatPasswordError: null,
  });

  const token = new URLSearchParams(location.search).get("token");
  const handleInputChange = (e, name) => {
    setLoginForm({ ...loginForm, [name]: e.target.value });
    if (
      PASSWORD_REGUEX.test(e.target.value) === true &&
      e.target.value.length >= 8
    ) {
      setPasswordError({ ...passwordError, passwordError: false });
    } else {
      setPasswordError({ ...passwordError, passwordError: true });
    }
    if (name === "repeatPassword") {
      if (loginForm.password !== e.target.value) {
        setPasswordError({ ...passwordError, repeatPasswordError: true });
      } else {
        setPasswordError({ ...passwordError, repeatPasswordError: false });
      }
    }
  };

  return (
    <div className={style.container}>
      <div className={style.containerContent}>
        <span className={style.header}>
          <img className={style.logo} src={LogoAppVertical} alt="Prikly" />
        </span>
        <h1 className={style.subTitle}>
          Escribe el correo electrónico asociado a la cuenta que deseas
          recuperar, te enviaremos un correo electrónico para verificar tu
          identidad. Sigue los pasos indicados en el correo.
        </h1>
        <div className={style.containerInput}>
          <PasswordInput
            width="100%"
            inputProps={{
              placeholder: "Ingresa tu contraseña",
              value: loginForm.password === null ? "" : loginForm.password,
              onChange: (e) => handleInputChange(e, "password"),
              type: isPasswordVisible ? "text" : "password",
            }}
            visibility={{
              isVisible: isPasswordVisible,
              setIsVisible: setIsPasswordVisible,
              isToggleAvailable: true,
            }}
            label="Contraseña"
            error={
              "La contraseña debe tener al menos 8 caracteres, 1 numero y 1 mayuscula"
            }
            touched={passwordError.passwordError}
            useCircleAlert={false}
            id="login_confirmNewPassword_password"
          />
          <PasswordInput
            inputProps={{
              placeholder: "Repite tu contraseña",
              value:
                loginForm.repeatPassword === null
                  ? ""
                  : loginForm.repeatPassword,
              onChange: (e) => handleInputChange(e, "repeatPassword"),
              type: isPasswordVisible ? "text" : "password",
            }}
            visibility={{
              isVisible: isPasswordVisible,
              setIsVisible: setIsPasswordVisible,
              isToggleAvailable: true,
            }}
            label={"Confirmar contraseña"}
            error={"Las contraseñas no coinciden"}
            touched={passwordError.repeatPasswordError}
            id="login_confirmNewPassword_confirmPassword"
          />
          <ButtonWithoutIcon
            width="100%"
            isPrimary={true}
            isDisabled={
              loginForm.password === null ||
              loginForm.password === "" ||
              loginForm.repeatPassword === null ||
              loginForm.repeatPassword === "" ||
              loginForm.repeatPassword.length < 8 ||
              passwordError.passwordError ||
              passwordError.repeatPasswordError ||
              isLoading
            }
            isLoading={isLoading}
            textBttn="Continuar"
            handleClick={() => {
              handleSubmitPassword(
                setIsLoading,
                token,
                loginForm.password,
                navigate
              );
            }}
            id="login_confirmNewPassword_click_submitForm"
          />
        </div>
      </div>
    </div>
  );
};
