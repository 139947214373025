import { SendPassword } from "@Adapters/sendThePassword/sendThePasswordAdapter";

export const handleSubmitPassword = async (
  setIsLoading,
  token,
  password,
  navigate
) => {
  setIsLoading(true);
  await SendPassword(token, password, navigate);
  setIsLoading(false);
};
