import { postLogin_service } from "@Services/login";

export const selectLocal_adapter = async (params) => {
  const response = await postLogin_service(params);

  if (!response.isSuccess) {
    return response;
  }

  const data = response.data;

  return {
    ...response,
    data: data,
  };
};
